<template>
  <div class="header-container">
    <link rel="stylesheet" id="jquery-ui-theme-css"
          href="https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/themes/cupertino/jquery-ui.min.css"
          type="text/css" media="all">
    <header :class="{'header-limited-width': isPagesWithBasket}">
      <a href="https://www.copenhagenluggageservice.com/" v-if="!isLinkList"><img src="../assets/images/luggage_service_logo.png"></a>
      <img v-else @click="openLinkList = !openLinkList" src="../assets/images/luggage_service_logo.png">
      <div class="link-list" v-if="openLinkList">
        <ul>
          <li><a href="/">Booking start page</a></li>
          <li><a href="https://www.copenhagenluggageservice.com/">Home page</a></li>
        </ul>
        <p font-size="50%">v20240902</p>
      </div>
      <slot v-if="isBook">
        <button class="book-btn" @click="scrollToService()">Book</button>
      </slot>
      <slot v-else-if="typeof (isBook) === 'undefined'">
        <div></div>
      </slot>
      <slot v-else>
        <Popup :header='"Basket"' :isArrival='isArrival'/>
      </slot>
    </header>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Popup from '@/components/Popup'

export default {
  name: 'Header',
  components: { Popup },
  props: ['isBook', 'isArrival'],
  data: () => ({
    openLinkList: false
  }),
  computed: {
    isLinkList () {
      return this.$route.path !== '/'
    },
    isPagesWithBasket () {
      return this.$route.path === '/arrival-info' ||
      this.$route.path === '/delivery-info' ||
      this.$route.path === '/order-details' ||
      this.$route.path === '/payment-form' ||
      this.$route.path === '/storage-info' ||
      this.$route.path === '/traveller-info'
    }
  },
  methods: {
    ...mapMutations(['setCloseAllPopups']),
    scrollToService () {
      const elemPos = document.querySelector('#service').offsetTop
      window.scrollTo({ top: elemPos - 30, behavior: 'smooth' })
      this.setCloseAllPopups(true)
      setTimeout(() => {
        this.setCloseAllPopups(false)
      }, 10)
    }
  }
}
</script>

<style scoped lang="scss">
  .header-container {
    position: fixed;
    top: 0px;
    width: 100%;
    max-height: 95px;
    background-color: #00a9e0;
    border-bottom: 3px solid #cccccc;
    z-index: 9999;
  }

  header {
    color: #9f9c9c;
    padding: 20px 0;
    margin-bottom: 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      justify-content: space-around;
    }
    @media (min-width: 800px) and (max-width: 1023px) {
      justify-content: space-between;
    }
    @media screen and (min-width: 1023px) {
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
    }
    img {
      cursor: pointer;
    }
  }

  .header-limited-width {
    @media screen and (min-width: 1023px) {
      width: auto;
      margin-left: 15%;
      margin-right: calc(15% - 20px);
    }
    @media screen and (min-width: 1435px) {
      margin-left: calc(15% + 20px);
      margin-right: 15%;
    }
  }

  .link-list {
    position: absolute;
    left: 0;
    top: 90px;
    width: 300px;
    height: 100vh;
    background-color: #00a9e0;
    z-index: 100;
    font-family: BlissLight;
    @media screen and (min-width: 1023px) {
      left: 13%;
    }
    @media (max-width: 800px) {
      top: 80px;
      padding-top: 10px;
    }
    @media (max-width: 360px) {
      top: 75px;
      padding-top: 15px;
    }
    ul {
      margin: 10px 30px;
    }
    li {
      color: white;
      list-style-type: none;
      margin-bottom: 30px;
      text-transform: none;
      font-size: 26px;
      cursor: pointer;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  header img {
    width: 180px;
    margin-left: 20px;
    height: auto;
  }

  header button {
    border: 2px solid #ffffff;
    width: 150px;
    height: 46px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #FC9000;
    margin-bottom: 5px;
  }

  p {
    width: auto;
    height: 3em;
    display: block;
    padding: 0.8em 1em;
    margin: 0px 20px 10px 0px;
    border: 1px solid #cccccc;
    color: #fff;
    text-align: center;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.1rem;
  }

  header > p::after {
    top: 40px;
    padding-left: 5px;
    font-family: FontAwesome;
    content: "\f291";
    color: #ffffff;
    cursor: pointer;
  }

  @media screen and (max-width: 484px) {
    .header-container {
      max-height: 85px;
    }

    p {
      font-size: 13px;
      padding: 0.8em 0.4em 0.8em 0.4em;
      letter-spacing: 0.1rem;
    }

    header img {
      width: 160px;
    }
  }

  @media screen and (max-width: 360px) {
    .header-container {
      max-height: 80px;
    }

    p {
      font-size: 11px;
      letter-spacing: 0.05rem;
    }

    header button {
      width: 120px;
      height: 38px;
      font-weight: normal;
    }
  }

  @media screen and (max-width: 280px) {
    p {
      font-size: 10px;
    }
  }

  p img {
    width: 1.22em;
    height: 1.22em;
  }

  .book-btn {
    margin-right: 20px;
  }
</style>
