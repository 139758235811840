<template>
  <div>
    <input
      type="text"
      class="for-popup input_full-width"
      v-model="$data[calendarId === 1 ? 'arrivalDate' : (calendarId === 2 ? 'storageDateFrom' : (calendarId === 3 ? 'storageDateEnd' : ''))]"
      @:change="info"
      :placeholder="placeholder"
      :id="`datepicker-${calendarId}`"
      autocomplete="off"
      readonly
      required
      :disabled="disabled"
    ><br>
    <small v-bind:class="`small-${calendarId}`" style="display: none">{{ this.validateDateText }}</small><br>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import $ from 'jquery'

export default {
  name: 'Calendar',
  props: ['calendarId', 'placeholder', 'minDate', 'disabled'],
  computed: mapGetters(['getOrderInfo', 'getStorageInfo', 'getDayLess', 'getUrlPrices']),
  data () {
    return {
      arrivalDate: '',
      storageDateFrom: '',
      storageDateEnd: '',
      validateDateText: 'Please, enter date only yyyy-mm-dd format',
      disabledDates:
      [
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28'
      ]
    }
  },
  mounted () {
    if (this.calendarId === 1) {
      this.calendar(`#datepicker-${this.calendarId}`, 'getOrderInfo', 'arrivalDate')
    }

    if (this.calendarId === 2) {
      this.calendar(`#datepicker-${this.calendarId}`, 'getStorageInfo', 'storageDateFrom')
    }

    if (this.calendarId === 3) {
      this.calendar(`#datepicker-${this.calendarId}`, 'getStorageInfo', 'storageDateEnd')
    }
    this.calendar()
  },
  created () {
    if (this.getOrderInfo.arrivalDate) {
      this.arrivalDate = this.getOrderInfo.arrivalDate
    }

    if (this.getStorageInfo.storageDateFrom) {
      this.storageDateFrom = this.getStorageInfo.storageDateFrom
    }

    if (this.getStorageInfo.storageDateEnd) {
      this.storageDateEnd = this.getStorageInfo.storageDateEnd
    }
  },
  methods: {
    ...mapMutations(['addDate', 'validator']),
    calendar (datepicker, storage, destination) {
      const VM = this
      $(datepicker).datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: datepicker === '#datepicker-3' ? this.minDate : this.getmindate(), // First available date
        beforeShowDay: function (date) {
          var string = $.datepicker.formatDate('yy-mm-dd', date)
          return [VM.disabledDates.indexOf(string) === -1]
        },
        onSelect: function (dateText) {
          this.date = dateText

          VM.addDate({
            [destination]: $(datepicker).val()
          })

          // console.log(VM[storage][destination])
          if (datepicker !== '#datepicker-3') {
            if (VM.getDayLess(dateText) && VM[storage][destination] !== '') {
              alert('Your arrival date is 2 days or less from now — late order fee will added')
            }
          }

          this[destination] = VM[storage][destination]
          VM.validateDate(dateText)
        }
      })
    },
    info () {
      if (this.calendarId === 1) this.validateDate(this.arrivalDate)
      if (this.calendarId === 2) this.validateDate(this.storageDateFrom)
      if (this.calendarId === 3) this.validateDate(this.storageDateEnd)

      if (this.calendarId === '1') {
        this.addDate({
          arrivalDate: $(`#datepicker-${this.calendarId}`).val()
        })
      }

      if (this.calendarId === '2') {
        this.addDate({
          storageDateFrom: $(`#datepicker-${this.calendarId}`).val()
        })
      }

      if (this.calendarId === '3') {
        this.addDate({
          storageDateEnd: $(`#datepicker-${this.calendarId}`).val()
        })
      }
    },
    validateDate (dataStr) {
      if (!(dataStr.match(/^\d{4}-\d{2}-\d{2}$/))) {
        $(`#datepicker-${this.calendarId}`).addClass('invalid')
        $(`.small-${this.calendarId}`).css('display', 'block')
        this.validator(dataStr)
      } else {
        $(`#datepicker-${this.calendarId}`).removeClass('invalid')
        $(`.small-${this.calendarId}`).css('display', 'none')
        this.validator(dataStr)
      }
    },
    getmindate () {
      // caculate how many days in advance booking should be available
      // min 36 hrs in low season
      // could be on the day in high season
      console.log('queryprice todayBookingAllowed = ' + this.getUrlPrices.todayBookingAllowed)
      var t = this.$func.getLocalTime() // get time in Denmark

      if (this.getUrlPrices.todayBookingAllowed) return 0
      if (this.$func.getHighSeason()) {
        if (t.getHours() >= 12) return 2 // ensure min 36 hrs before service in low season
        else return 2
      } else {
        if (t.getHours() >= 10) return 2 // ensure min 36 hrs before service in low season
        else return 1
      }
    }
  },
  watch: {
    minDate () {
      $('#datepicker-3').datepicker('destroy')
      $('#datepicker-3').val('')
      this.getStorageInfo.storageDateEnd = ''
      this.calendar('#datepicker-3', 'getStorageInfo', 'storageDateEnd')
    }
  }
}
</script>

<style scoped lang="scss">
  input, select {
    width: 20em;
    height: 40px;
    line-height: 3em;
    border: 1px solid #cccccc;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 1px #cccccc;
    text-align: center;
  }

  .invalid {
    border: 1px solid #ff0000;
  }

  small {
    display: block;
    margin-top: 0.4em;
    color: #ff0000;
  }
</style>
