<template>
  <div class="home">
    <Header />

    <div v-if="loading" class="loading-block">
      <h2>The order is being processed.</h2>
      <h2>Just a few seconds left...</h2>
      <div class="loader-after-payment" />
    </div>

    <div v-else class="intro">
      <h2>Thank you<br> for placing your order at</h2>
      <br><br>
      <h1>Copenhagen<br> Luggage Service</h1>

      <div class="first-block">
        <hr>
        <h2>For order confirmation<br> and next steps:</h2><br>
        <h3>Please check your email</h3>
        <img src="../assets/images/your_travel_we_carry.png">
        <div class="buttons-container btns-confirmation">
          <button class="btn-back" onclick="location.href='/#service'">Go back to Main page</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import Header from '@/components/Header'

export default {
  name: 'AfterPayment',

  components: {
    Header
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters([
      'getOrderInfo', 'getStorageInfo', 'getCustomerInfo', 'getStateData', 'getPaymentIntent',
      'getAllBagsCount', 'priceCalculation', 'getStorageDays', 'getUrlPrices'
    ])
  },

  methods: {
    formatDate (date) {
      if (typeof date === 'string') {
        date = new Date(Date.parse(date))
      }
      return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
    },
    async getInfoFromVuex () {
      let refValue = document.cookie.split(';') || []

      if (refValue.length) {
        refValue = refValue.find(c => c.includes('ref=')) || ''
        if (refValue) {
          refValue = refValue.split('=')[1]
        }
      }

      const dateNow = new Date()
      const objectMomentTimeZone = moment.tz('Europe/Copenhagen')
      const orderDate = objectMomentTimeZone.format('DD.MM.YYYY')
      const orderTime = objectMomentTimeZone.format('HH:mm:ss')
      const totalObj = {}

      totalObj.customerInfo = {
        ...this.getCustomerInfo,
        ref: refValue
      }

      totalObj.additionalInfo = this.getStateData.additionalInfo

      if (!this.getStorageInfo.numberOfBags && !this.getStorageInfo.numberOfHeavyBags) {
        totalObj.orderInfo = this.getOrderInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(true)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(true)
        totalObj.orderInfo.arrivalDate = this.formatDate(this.getOrderInfo.arrivalDate)
        totalObj.orderInfo.orderDate = orderDate
        totalObj.orderInfo.orderTime = orderTime
        totalObj.orderInfo.orderChangedDate = Date.parse(dateNow)
        totalObj.orderInfo.paymentId = this.getPaymentIntent
        totalObj.orderInfo.noprintself = false
        totalObj.orderInfo.isOpen = true
      } else {
        totalObj.storageInfo = this.getStorageInfo
        totalObj.additionalInfo.priceInfo = this.priceCalculation(false)
        totalObj.additionalInfo.totalBags = this.getAllBagsCount(false)
        totalObj.storageInfo.storageDateFrom = this.formatDate(this.getStorageInfo.storageDateFrom)
        totalObj.storageInfo.storageDateEnd = this.formatDate(this.getStorageInfo.storageDateEnd)
        totalObj.additionalInfo.storageDays = this.getStorageDays
        console.log('****sto days = ' + this.getStorageDays)
        totalObj.storageInfo.orderDate = orderDate
        totalObj.storageInfo.orderTime = orderTime
        totalObj.storageInfo.paymentId = this.getPaymentIntent
      }

      totalObj.additionalInfo.paidInfo = '' // (this.getUrlPrices.skipPayment === 1) ? 'Not paid' : 'Paid'
      console.log('payment ' + totalObj.additionalInfo.paidInfo)

      try {
        this.loading = false

        await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
          ? process.env.VUE_APP_API_SERVER
          : 'http://localhost:3100'}/order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(totalObj)
        })

        this.$gtm.trackEvent({
          event: 'afterPayment',
          category: 'order',
          action: 'Event after successful payment',
          label: 'Order fulfilled',
          value: this.getPaymentIntent
        })
      } catch (err) {
        console.log('An error was occured ' + err.name)
      } finally {
        this.loading = false
      }
    }
  },

  async created () {
    if (this.getUrlPrices.skipPayment !== 1) {
      await this.getInfoFromVuex()
    }
  }
}
</script>

<style scoped lang="scss">
   h2 {
    font-family: BlissLight;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #53565A;
  }

  img {
    width: 60%;
  }

  .btns-confirmation {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .btn-back {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    left: 50%;
    padding: 8px;
  }

  .loading-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 50px 0;
    height: 100vh;
  }

  .loader-after-payment {
    border: 7px solid #00a9e0; /* Light grey */
    border-top: 7px solid #f3f3f3; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
</style>
